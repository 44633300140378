// React
import { FC, ChangeEvent, useState, useEffect } from "react";

// Next Intl
import { useLocale, useTranslations } from "next-intl";

// Components
import { Button } from "@/components/ui/button";
import FeedbackForm from "@/components/ui/FeedbackForm";
import { HiOutlinePaperAirplane, HiOutlineTranslate } from "react-icons/hi";

// Utils
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import Clock from "../ui/clock";

// Cookies
import cookie from "js-cookie";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Footer: FC<Props> = ({ fqcn_bui }) => {
  const [loginForm, setLoginForm] = useState(false);

  const locale = useLocale();

  const t = useTranslations("Footer");

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLocale = e.target.value;
    const currentPathname = window.location.pathname;
    const newPathname = currentPathname.replace(
      /\/(en|fr)\b/,
      `/${selectedLocale}`
    );
    window.location.href = newPathname;
    cookie.set("NEXT_LOCALE", selectedLocale);
  };

  useEffect(() => {
    const preferences = localStorage.getItem("preferences");
    if (preferences && locale) {
      const parsedPreferences = JSON.parse(preferences);
      parsedPreferences.locale = locale;
      localStorage.setItem("preferences", JSON.stringify(parsedPreferences));
    }

    if (locale) {
      cookie.set("NEXT_LOCALE", locale);
    }
  }, [locale]);

  return (
    <footer className="text-sm md:text-base flex justify-around bg-gray-300 dark:bg-gray-800 p-3 border w-full text-gray-500 gap-2 print:hidden">
      <div className="hidden md:block ">{/*empty div for spacing*/}</div>
      <div
        id={getFQCN(fqcn_bui, "footer")}
        className="flex w-60 md:w-auto items-center flex-col"
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <article className="flex">
                <HiOutlineTranslate
                  id={getFQCN(fqcn_bui, "footer", "traduction-icon")}
                  className="h-6 w-6 mt-1"
                />
                &nbsp;
                <select
                  id={getFQCN(fqcn_bui, "footer", "select-language")}
                  className="rounded-md px-2 py-1 dark:bg-gray-600 border-[0.5px] border-gray-500 dark:text-gray-300 hover:cursor-pointer"
                  onChange={handleLanguageChange}
                  value={locale}
                >
                  <option
                    id={getFQCN(fqcn_bui, "footer", "option-en")}
                    value="en"
                    className="dark:bg-gray-600 dark:text-gray-300"
                  >
                    English
                  </option>
                  <option
                    id={getFQCN(fqcn_bui, "footer", "option-fr")}
                    value="fr"
                    className="dark:bg-gray-600 dark:text-gray-300"
                  >
                    Français
                  </option>
                </select>
              </article>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("Traduction")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <article>
          <FeedbackForm
            onClose={() => setLoginForm(false)}
            isOpen={loginForm}
          />
          <Button
            id={getFQCN(fqcn_bui, "footer", "report-button")}
            name="problem-button"
            className="bg-transparent text-gray-500 p-0 h-full hover:bg-transparent hover:text-gray-400 duration-300 ease-in-out"
            onClick={() => setLoginForm(true)}
          >
            <HiOutlinePaperAirplane
              id={getFQCN(fqcn_bui, "footer", "probleme-icon")}
              className="h-5 w-5"
            />
            &nbsp;
            {t("Report a problem")}
          </Button>
        </article>

        <article className="flex justify-center items-center ">
          {t("Powered by")}
          <span className="text-grey-600 font-bold pr-1 pl-1">
            <a href="https://phpcreation.com/">PHPCreation Inc.</a>
          </span>
        </article>
      </div>
      <div className="flex justify-center flex-col">
        <Clock />
      </div>
    </footer>
  );
};

export default Footer;
